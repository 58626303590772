<template>
  <div>
    <main>
      <Article>
        <template v-slot:swirl>
          <swirl :pageId="483" />
        </template>

        <template v-slot:article__header>
          <ArticleHeader
            :heading="`${gql.data.page.title}`"
            :copy="`${gql.data.page.acf.subtitle}`"
          />
        </template>

        <template v-if="gql.data.page.featuredImage" v-slot:article__image>
          <Visual>
            <img
              width="640"
              height="480"
              :src="gql.data.page.featuredImage.node.sourceUrl"
              :alt="gql.data.page.featuredImage.node.altText"
            />
          </Visual>
        </template>

        <template v-slot:content__copy>
          <copy v-html="gql.data.page.content" />
        </template>

        <template v-slot:content__scroll>
          <scroll-to :anchor="'#scroll-to'">
            {{ gql.data.page.acf.scrollto }}
          </scroll-to>
        </template>
      </Article>

      <div id="scroll-to" class="over-trainers">
        <div
          v-for="(trainer, index) in gql.data.trainers.nodes"
          :key="trainer.name"
          :class="{
            trainer: true,
            'trainer--img-leading': index % 2 === 0,
            'trainer--img-trailing': index % 2 !== 0,
          }"
        >
          <div class="trainer__copy">
            <h2 class="explainer__title">{{ trainer.title }}</h2>
            <span v-html="trainer.content" />
          </div>

          <div class="trainer__image" v-if="trainer.featuredImage">
            <img
              width="400"
              :src="trainer.featuredImage.node.sourceUrl"
              :alt="trainer.featuredImage.node.altText"
            />
          </div>
        </div>
      </div>
    </main>
    <Footer>
      <template v-slot:links>
        <links :links="links" />
      </template>
    </Footer>
  </div>
</template>

<script>
import Article from "../components/Article.vue";
import ArticleHeader from "../components/ArticleHeader.vue";
import Visual from "../components/ArticleImage.vue";

import Copy from "../components/ContentCopy.vue";
import Links from "../components/Links.vue";
import Footer from "../components/Footer.vue";
import Swirl from "../components/Swirl.vue";
import ScrollTo from "../components/ContentScroll.vue";

export default {
  name: "OverTrainers",
  data: function () {
    return {
      links: [
        {
          name: "coronaProof",
          copyText: "Ik wil meer weten over Fitmark en corona",
          ctaText: "Ga naar de covid pagina",
        },
      ],
      gql: {
        data: {
          page: {
            content:
              "\n<p>Fitmark is in 2020 begonnen met een aantal enthousiaste sporters. Inmiddels zijn we uitgegroeid tot een volwaardig fitnessbedrijf met verschillende trainers. Inmiddels werkt Fitmark samen met meerdere trainers. Zij stellen zich graag aan je voor:</p>\n",
            title: "Over trainers",
            featuredImage: null,
            acf: {
              subtitle: "Wie werken er bij Fitmark?",
              scrollto: "Maak kennis met de trainers van Fitmark",
              __typename: "Page_Acf",
            },
            __typename: "Page",
          },
          trainers: {
            nodes: [
              {
                content:
                  "\n<p><strong><em>“Ik heb een passie voor sport en gezondheid en vind het tof om mensen te helpen met hun fysieke en mentale gezondheid!”</em></strong></p>\n\n\n\n<p>Mandy is een sociaal en rustig persoon, die als personal trainer aandacht heeft voor haar sporters. Haar trainingen zijn altijd aangepast op jouw belastbaarheid. <br>Als kind heeft ze aan hockey gedaan. Tijdens haar hockey trainingen deed ze al weleens aan krachttraining. Toen ze eenmaal gestopt was met hockey spelen is zij zich hier meer op gaan focussen.</p>\n\n\n\n<p>Haar passie voor sport heeft een vervolg gekregen op het CIOS. In 2021 is zij afgestudeerd als coördinator sport en gezondheid. Tijdens deze studie heeft ze ook haar fitness A+B, NASM personal trainer en sportmassage certificaat gehaald. Op dit moment studeert ze fysiotherapie aan de HVA en is ze werkzaam als personal trainer bij de Achilles Personal Gym in Haarlem. Daarnaast werkt ze als bootcamptrainer voor Fitmark.</p>\n",
                title: "Mandy",
                featuredImage: {
                  node: {
                    altText: "",
                    sourceUrl:
                      "https://content.fitmark.nl/wp-content/uploads/2022/11/Mandy-foto-Achilles-960x960.jpg",
                    __typename: "MediaItem",
                  },
                  __typename: "NodeWithFeaturedImageToMediaItemConnectionEdge",
                },
                __typename: "Trainer",
              },
              {
                content:
                  "\n<p><strong><em>“Ik vind het belangrijk dat mensen van jongs af aan bewust omgaan met hun gezondheid. De gevolgen van inactiviteit zijn namelijk op latere leeftijd onomkeerbaar!”</em></strong></p>\n\n\n\n<p>Mette is een enthousiaste en sociale trainer. Ze vindt het leuk om met mensen in contact te staan. Ze heeft al van jongs af aan affiniteit met sport. Als kind heeft ze, naast vele andere sporten, jarenlang gehandbald. Het was voor haar dan ook geen moeilijke keuze om na de middelbare school te gaan studeren aan het CIOS in Haarlem. Tijdens deze studie trok het vak fitness haar erg aan en in 2021 is zij afgestudeerd als fitness, health en performance coach.</p>\n\n\n\n<p>Mette wil zich blijven ontwikkelen en studeert momenteel fysiotherapie aan de HVA. Na haar studie wil ze haar sportleden met alle opgedane kennis nog beter begeleiden. Momenteel werkt ze als personal trainer bij de Achilles Personal Gym in Haarlem en als bootcamptrainer voor Fitmark.</p>\n",
                title: "Mette",
                featuredImage: {
                  node: {
                    altText: "",
                    sourceUrl:
                      "https://content.fitmark.nl/wp-content/uploads/2022/11/Mette-960x960.jpg",
                    __typename: "MediaItem",
                  },
                  __typename: "NodeWithFeaturedImageToMediaItemConnectionEdge",
                },
                __typename: "Trainer",
              },
              {
                content:
                  "\n<p><strong><em>“Creëren is wat ik het liefst doe!”</em></strong></p>\n\n\n\n<p>Mark is de oprichter van Fitmark. Hij heeft gestudeerd aan het CIOS en de ALO en beide studies succesvol afgerond. Ook heeft hij een opleiding tot personal trainer gevolgd bij Chivo; kennisinstituut vitaliteit en lifestyle. De afgelopen jaren is Mark als gymdocent werkzaam geweest op een vrije school in Haarlem en geeft sinds 2017 surflessen in het zomerseizoen. </p>\n\n\n\n<p>Vanuit zijn passie voor fitness en surfen is Fitmark ontstaan. Hij haalt voldoening en plezier uit het begeleiden van mensen en hen ondersteunen in het realiseren van een vitaal leven. Een vitaal leven gaat verder dan alleen het advies over voldoende beweging en gezonde voeding. Vitaliteit heeft betrekking op een gezond evenwicht tussen inspanning en ontspanning van lichaam en geest en stelt ons daarmee in staat betekenis te geven aan het leven. De elementen beweging, voeding, natuur, ontspanning en plezier kunnen volgens hem een krachtige bijdrage leveren aan dit proces</p>\n",
                title: "Mark",
                featuredImage: {
                  node: {
                    altText: "",
                    sourceUrl:
                      "https://content.fitmark.nl/wp-content/uploads/2022/11/mark-berk2-960x960.jpg",
                    __typename: "MediaItem",
                  },
                  __typename: "NodeWithFeaturedImageToMediaItemConnectionEdge",
                },
                __typename: "Trainer",
              },
            ],
            __typename: "RootQueryToTrainerConnection",
          },
        },
      },
    };
  },
  computed: {
    trainers() {
      return this.gql.data.trainers.nodes;
    },
  },
  components: {
    Article,
    ArticleHeader,
    Visual,
    Links,
    Footer,
    Copy,
    Swirl,
    ScrollTo,
  },
};
</script>
<style lang="scss" scoped></style>
